
import { defineComponent } from "vue";
import store from "@/store";
import SelectLanguage from "@/buying-teams/shared-components/utils/SelectLanguage.vue";

export default defineComponent({
    name: "auth",
    components: {
        SelectLanguage
    },
    computed: {
        platformConfigs() {
            return store.getters.platformConfigs;
        }
    }
});
