import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex h-100 bgi-no-repeat bgi-size-cover bgi-attachment-fixed auth-bg flex-column" }
const _hoisted_2 = {
  class: "auth-content w-100",
  style: {"background-image":"url('/media/logos/buying-logo-auth-bg.png')","background-repeat":"no-repeat","background-position":"center"}
}
const _hoisted_3 = { class: "auth-content_container" }
const _hoisted_4 = { class: "auth-content_container__header" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "auth-content_container__footer" }
const _hoisted_7 = { class: "footer-title" }
const _hoisted_8 = { class: "footer-link" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.platformConfigs.platformLogos.whiteLogo,
            alt: ""
          }, null, 8, _hoisted_5)
        ]),
        _createVNode(_component_router_view)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('To learn More')), 1),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("a", {
          href: _ctx.platformConfigs.platformDomain,
          target: "_blank"
        }, _toDisplayString(_ctx.$t('Visit Our Webpage')), 9, _hoisted_9)
      ])
    ])
  ]))
}